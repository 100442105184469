import React from "react";
import "../styles/HeroSection.css";

const HeroContainer = ({ children }: any) => {
  return (
    // <div className="grid place-items-center lg:p-0 ">
    //   <div className="flex flex-col lg:flex-row ">
    //     <div className="bg-hero-div-bg lg:w-[45.563rem] lg:h-[41.75rem] w-[22.375rem]  h-[25.188rem] rounded-lg">
    //       <p>tese</p>
    //     </div>
    //     <div className=" lg:w-[45.563rem] lg:h-[41.75rem] w-[22.375rem]   h-[25.188rem] rounded-lg mt-5 lg:mt-0  ">
    //       <div className="grid grid-cols-1">
    //         {/* Image 1 (Bottom) */}
    //         <img
    //           className="w-[29.188rem] row-start-1 col-start-1 mt-10  "
    //           src={require("../iamges/ImageOne.png")}
    //           alt="Image One"
    //         />

    //         {/* Image 2 (Middle) */}
    //         <img
    //           className="w-[29.875rem] row-start-1 col-start-1 lg:ml-10  mt-10 "
    //           src={require("../iamges/iamgeTwo.png")}
    //           alt="Image Two"
    //         />

    //         {/* Screen 3 (Top) */}
    //         <img
    //           className="w-[25.938rem] row-start-1 col-start-1  lg:ml-20"
    //           src={require("../iamges/Screen 3.png")}
    //           alt="Screen 3"
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="grid place-items-center lg:ml-[15rem] ">
      <div className="flex flex-col lg:flex-row ">
        <div
          className="bg-hero-div-bg lg:w-[45.563rem] lg:h-[41.75rem] w-[22.375rem] h-[25.188rem] rounded-lg pl-8 lg:pt-44 md:pt-44 pt-7 pr-10  "
          style={{
            display: "flex",

            justifyContent: "",
            flexDirection: "column",
          }}
        >
          <div className="flex items-center bg-[rgba(255,157,0,0.06)] text-white font-semibold rounded-full p-2 shadow-lg h-9   w-80">
            <div
              className=" text-white font-semibold rounded-full py-1 px-3 mr-4 flex items-center "
              style={{
                background: "#FFB200",
              }}
            >
              New
            </div>

            <a href="#" className="  " style={{ color: "#0A0A0A" }}>
              <span>Download the new app</span>
            </a>
          </div>
          <div className="mt-5">
            <h1 className="lg:text-5xl text-[26px] text-[#272D37]">
              Your Seamless Journey from
            </h1>
            <h1
              className="lg:text-5xl text-[26px] text-[#272D37] "
              style={{ fontWeight: "800" }}
            >
              Move to Maintenance
            </h1>
          </div>
          <div>
            <img
              src={require("../iamges/Vector.png")}
              alt=""
              className="w-[25rem]"
            />
          </div>
          <p>
            We’ve been told it is not possible to overachieve our customers’
            expectations. We have not reinvented the wheel, we decided to build
            upon it.
          </p>
          <div className="mt-7">
            <button className="w-[7.5rem] h-[2rem] bg-[#2C3444] rounded-md ">
              <a href="https://play.google.com/store/apps/details?id=com.arptix.homeowner">
                <img
                  src={require("../iamges/playstore.png")}
                  style={{ width: 88 }}
                  alt=""
                  className="mx-auto"
                />
              </a>
            </button>
            <button className="w-[7.5rem] h-[2rem] bg-[#2C3444] rounded-md ml-7">
              <a href="https://apps.apple.com/ca/app/homeowner/id6448795356">
                <img
                  style={{ width: 88 }}
                  src={require("../iamges/appleStore.png")}
                  alt=""
                  className="mx-auto"
                />
              </a>
            </button>
          </div>
        </div>

        <div className="lg:w-[45.563rem]  lg:h-[41.75rem] w-[22.375rem] h-[25.188rem] rounded-lg mt-16 lg:mt-0 ">
          <div className="grid grid-cols-1">
            {/* Image 1 (Bottom) */}
            <img
              className="w-[35rem] row-start-1 col-start-1 mt-10"
              src={require("../iamges/ImageOne.png")}
              alt="Image One"
            />

            {/* Image 2 (Middle) */}
            <img
              className="w-[33rem] row-start-1 col-start-1 mt-10 "
              src={require("../iamges/iamgeTwo.png")}
              alt="Image Two"
            />

            {/* Screen 3 (Top) */}
            <img
              className="w-[25.938rem] row-start-1 col-start-1 lg:ml-20"
              src={require("../iamges/Screen 3.png")}
              alt="Screen 3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroContainer;
