import { Styles } from "./App";
import "../styles/BottomSection.css";
import { Link } from "react-router-dom";
const BottomSection = () => {
  return (
    <div className="bg-[#171717] text-white py-16 px-8">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="md:w-1/2 space-y-4">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight">
            Stay ahead with your home management
          </h1>
          <p className="text-gray-400">
            Generate outside the box thinking with the possibility to target the
            low
          </p>
        </div>

        {/* <div className="md:w-1/2 mt-8 md:mt-0 flex justify-end">
          <div className=" p-4 rounded-lg flex items-center ">
            <input
              type="email"
              placeholder="Enter email..."
              className="bg-gray-700 text-white p-2 rounded-lg "
            />
            <button className="bg-orange-400 text-white font-semibold py-2 px-4 rounded-lg hover:bg-orange-500">
              Submit
            </button>
          </div>
        </div> */}
      </div>

      <div className="max-w-6xl mx-auto mt-12 grid grid-cols-1 md:grid-cols-4 gap-8">
        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <img className="w-8 h-8 text-green-600" src="./favicon.png" />

            <span className="text-2xl font-bold">HomeOwner</span>
          </div>
          <p className="text-gray-400">
            Generate outside the box thinking with the possibility to target the
            low.
          </p>
        </div>
        <Link to={"/policy"}>
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Privacy policy</h3>
          </div>
        </Link>
        <Link to={"/accountDelete"}>
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Account deletion</h3>
          </div>
        </Link>
        {/* <div className="space-y-2">
          <h3 className="text-lg font-semibold">Company</h3>
        </div> */}
      </div>
    </div>
  );
};

export default BottomSection;
