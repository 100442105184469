import React from "react";
import NavBar from "./NavBar";
import BottomSection from "./BottomSection";

const PrivacyPolicy = () => {
  return (
    <div>
      <NavBar />
      {/* <div
        className="mx-auto"
        style={{
          background: "rgba(255, 157, 0, 0.12)",
          width: "55%",
          height: 267,
          borderRadius: 32,
        }}
      ></div> */}
      <div className="max-w-3xl mx-auto my-10 px-6">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          Privacy Policy
        </h1>
        <p className="text-center text-gray-600 mt-2">
          Arptix Solutions Inc ("us", "we", or "our") operates the HomeOwner
          mobile application (the "Service"). This page informs you of our
          policies regarding the collection, use, and disclosure of personal
          data when you use our Service and the choices you have associated with
          that data.
        </p>
        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Information Collection and Use
          </h2>
          <p className="text-gray-600 mt-2">
            We collect several different types of information for various
            purposes to provide and improve our Service to you. Personal Data
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data").
          </p>
          <ul className="list-disc list-inside text-gray-600 mt-2">
            <li>Full Name</li>
            <li>Current Address</li>
            <li>New Address</li>
            <li>Moving Date</li>
            <li>Email Address</li>
          </ul>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">Usage Data</h2>
          <p className="text-gray-600 mt-2">
            We may also collect information that your browser sends whenever you
            visit our Service or when you access the Service by or through a
            mobile device ("Usage Data").
          </p>
          <p className="text-gray-600 mt-2">
            Cookies and Similar Technologies: We may use cookies and similar
            tracking technologies to track activity on our Service and hold
            certain information.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Self-Service Data Deletion
          </h2>
          <p className="text-gray-600 mt-2">
            Users have the ability to delete their account and all associated
            personal data directly through the app at any time. This process is
            irreversible and will result in the permanent removal of the user’s
            personal information from our databases.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Document Uploads and Data Sensitivity
          </h2>
          <p className="text-gray-600 mt-2">
            For the document upload functionality, we advise users not to upload
            documents containing sensitive personal information. Uploaded
            documents are stored with high confidentiality and are not accessed
            or shared by us or any third parties. Users can delete these
            documents at their discretion.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Security Practices and Disclaimer
          </h2>
          <p className="text-gray-600 mt-2">
            We are committed to ensuring the security and protection of the
            personal data that we process, and to providing a compliant and
            consistent approach to data protection. We have implemented various
            security measures including, but not limited to, encryption, access
            controls, secure software development practices, and regular
            security training for our staff.
          </p>
          <p className="text-gray-600 mt-2">
            However, no method of transmission over the Internet, or method of
            electronic storage, is 100% secure. Therefore, while we strive to
            use commercially acceptable means to protect your Personal Data, we
            cannot guarantee its absolute security.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Non-Disclosure of Information
          </h2>
          <p className="text-gray-600 mt-2">
            We do not share any personal data or uploaded documents with third
            parties. Your privacy is our top priority.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Changes to This Privacy Policy
          </h2>
          <p className="text-gray-600 mt-2">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
        </section>
      </div>

      <BottomSection />
    </div>
  );
};

export default PrivacyPolicy;
