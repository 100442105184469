import BottomSection from "./BottomSection";
import ContactUs from "./ContactUs";
import NavBar from "./NavBar";

export default function AccoutDelete() {
  return (
    <div>
      <NavBar />
      <div className="max-w-3xl mx-auto my-10 px-6">
        <h1 className="text-3xl font-bold text-gray-900 text-center">
          Account Deletion
        </h1>

        <div className="max-w-3xl mx-auto my-10 px-6">
          <div className="mt-6 bg-gray-100 p-6 rounded-lg shadow-md">
            <p className="text-gray-700 text-lg">
              Follow these steps to delete your account permanently:
            </p>
            <ul className="list-decimal list-inside mt-4 space-y-2 text-gray-700">
              <li>
                Navigate to the <strong>Settings</strong> tab in the
                application.
              </li>
              <li>
                Inside <strong>Settings</strong>, select the{" "}
                <strong>Account Settings</strong> option.
              </li>
              <li>
                In the <strong>Account Settings</strong> section, review and
                accept the <strong>Terms and Conditions</strong>.
              </li>
              <li>
                Once the Terms and Conditions are accepted, locate and press the{" "}
                <strong>Delete Account</strong> button.
              </li>

              <li>
                Your account will be <strong>permanently deleted</strong>.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ContactUs />
      <BottomSection />
    </div>
  );
}
