import React from "react";

const ContactUs = () => {
  return (
    <div className="max-w-5xl mx-auto my-10 flex flex-col md:flex-row items-center">
      {/* Left Side - Text Content */}
      <div className="w-full md:w-1/2 h-80 p-6 bg-[#EBF0DD] flex flex-col justify-center">
        <h2 className="text-3xl font-bold text-gray-900">Contact us</h2>
        <p className="text-gray-700 mt-4">
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <a
            href="mailto:info@thehomeowner.ca"
            className="text-blue-600 underline font-medium"
          >
            info@thehomeowner.ca
          </a>
          .
        </p>
      </div>

      {/* Right Side - Image */}
      <div className="w-full md:w-1/2 h-80">
        <img
          src={require("../iamges/contact.png")}
          alt="Contact us illustration"
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

export default ContactUs;
