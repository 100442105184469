import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import getPlatform from "../utils/getPlatform";

const WhatWeProvide = () => {
  const [hovered, setHovered] = useState<number | null>(null);

  const cards = [
    {
      label: "Before Move",
      description:
        "Experience a smooth move with everything from logistics to utility registration. Stay organized with custom checklists and reminders tailored to your needs.",
      imgSrc: require("../iamges/beforeMove.png"),
    },
    {
      label: "After Move",
      description:
        "Settle in quickly with custom checklists and reminders. From changing your address to setting up your new space, we’ve got you covered.",
      imgSrc: require("../iamges/afterMove.png"),
    },
    {
      label: "Buying Home",
      description:
        "Navigate the home-buying process with ease. Get tips, tools, and checklists for affordability, mortgages, and more.",
      imgSrc: require("../iamges/buyingHome.png"),
    },
    {
      label: "Home Maintenance",
      description:
        "Keep your home in top shape with custom maintenance reminders. Stay on top of seasonal and routine tasks effortlessly.",
      imgSrc: require("../iamges/homeMaintainance.png"),
    },
    {
      label: "Selling Home",
      description:
        "Simplify selling your home with organized checklists for paperwork, listings, and essential tasks. We make it hassle-free.",
      imgSrc: require("../iamges/sellingHome.png"),
    },
  ];
  const alternateImages = [
    {
      label: "Before Move",
      imgSrc: require("../iamges/Frame 1171276041.png"),
      index: 0,
    },
    {
      label: "After Move",
      imgSrc: require("../iamges/afterMoveHover.jfif"),
      index: 1,
    },
    {
      label: "Buying Home",
      imgSrc: require("../iamges/buyingHomeHover.jfif"),
      index: 2,
    },
    {
      label: "Home Maintenance",
      imgSrc: require("../iamges/homeMaintainanceHover.jfif"),
      index: 3,
    },
    {
      label: "Selling Home",
      imgSrc: require("../iamges/sellingHomeHover.jfif"),
      index: 4,
    },
  ];

  // Find the alternate image based on the hovered card's label
  console.log("hoverd", hovered);

  const hoveredImage = hovered
    ? alternateImages.find((img) => img.index === hovered)?.imgSrc
    : require("../iamges/Frame 1171276041.png");

  const [isBelow981, setIsBelow981] = useState(window.innerWidth < 981);

  useEffect(() => {
    const handleResize = () => {
      setIsBelow981(window.innerWidth < 981);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const platform = getPlatform();
  console.log("User Platform:", platform);
  const downloadLinks: { [key: string]: string } = {
    Android:
      "https://play.google.com/store/apps/details?id=com.arptix.homeowner",
    iOS: "https://apps.apple.com/ca/app/homeowner/id6448795356",
    Windows:
      "https://play.google.com/store/apps/details?id=com.arptix.homeowner",
    Mac: "https://apps.apple.com/ca/app/homeowner/id6448795356",
    Linux: "https://www.example.com/linux-download",
  };

  // Fallback URL if the platform is unknown
  const downloadUrl = downloadLinks[platform] || "https://www.example.com";
  return (
    // <>
    //   <h1 style={{ textAlign: "center", marginTop: 41 }}>Services</h1>

    //   <div className="grid place-items-center lg:p-0 ">
    //     <div className="flex flex-col lg:flex-row ">
    //       <div className=" lg:w-[45.563rem] lg:h-[53.125rem] w-[22.375rem] h-[25.188rem] bg-green-500 rounded-lg"></div>
    //       <div className="lg:w-[45.563rem]  lg:h-[41.75rem] w-[22.375rem] h-[25.188rem] rounded-lg mt-5 lg:mt-0 lg:ml-2">
    //         <div className="grid grid-cols-1">
    //           <img
    //             src={require("../iamges/Frame 1171276041.png")}
    //             className="w-[37rem]"
    //             alt=""
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
    <>
      <h1 style={{ textAlign: "center", marginTop: 41, fontSize: 16 }}>
        Services
      </h1>
      <p
        style={{
          textAlign: "center",
          fontSize: 40,
          color: "#101828",
          marginBottom: 5,
        }}
      >
        What we provide?
      </p>

      <div className="grid place-items-center lg:p-0 mb-40">
        <div className="flex flex-col lg:flex-row justify-center items-start">
          {/* First div: Card functionality */}
          {!isBelow981 ? (
            <div className="lg:w-[45.563rem] lg:h-[53.125rem] w-[22.375rem] h-[25.188rem] rounded-lg">
              <div
                className="flex flex-col space-y-4 lg:h-[53.125rem]"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                {cards.map((card, index) => (
                  <div
                    key={index}
                    onMouseEnter={() => setHovered(index)}
                    onMouseLeave={() => setHovered(null)}
                    className="transition-all duration-300 ease-in-out transform "
                  >
                    {hovered === index ? (
                      <div className="rounded-[20px] border border-[rgba(0,0,0,0.08)] bg-[rgba(255,157,0,0.06)] p-4 transition-all duration-300 ease-in-out">
                        <div className="h-[61px] w-[61px] bg-[#FF9D00] rounded-full flex justify-center items-center">
                          <img
                            src={card.imgSrc}
                            className="w-[2rem]"
                            alt={card.label}
                          />
                        </div>
                        <h1 className="text-xl font-bold ">{card.label}</h1>
                        <p
                          className=""
                          style={{ fontFamily: "Plus Jakarta Sans" }}
                        >
                          {card.description}
                        </p>
                        {/* <div className="">
                          <button className="mt-4 py-2 px-4 bg-orange-400 text-white rounded-lg">
                            Download
                          </button>
                        </div> */}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className="h-[61px] w-[61px] bg-[#000] rounded-full flex justify-center items-center">
                          <img
                            src={card.imgSrc}
                            className="w-[2rem]"
                            alt={card.label}
                          />
                        </div>
                        <h1 className="text-xl font-bold ml-6">{card.label}</h1>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            cards.map((card, index) => (
              <div className="rounded-[20px] border border-[rgba(0,0,0,0.08)] bg-[rgba(255,157,0,0.06)] p-4 mx-3 mt-5">
                <div className="h-[61px] w-[61px] bg-[#FF9D00] rounded-full flex justify-center items-center">
                  <img
                    src={card.imgSrc}
                    className="w-[2rem]"
                    alt={card.label}
                  />
                </div>
                <h1
                  className="text-xl font-bold "
                  style={{
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: 600,
                  }}
                >
                  {card.label}
                </h1>
                <p
                  className=""
                  style={{ fontFamily: "Plus Jakarta Sans", fontWeight: 400 }}
                >
                  {card.description}
                </p>
                {/* <div className="">
                  <button className="mt-4 py-2 px-4 bg-orange-400 text-white rounded-lg">
                    Download
                  </button>
                </div> */}
              </div>
            ))
          )}

          {/* Second div: Static image */}
          <div className="lg:w-[45.563rem] lg:h-[41.75rem] w-[22.375rem] h-[25.188rem] rounded-lg mt-5 lg:mt-0 lg:ml-2 mx-auto ">
            <div className="grid grid-cols-1">
              <img
                // src={require("../iamges/Frame 1171276041.png")}
                src={hoveredImage}
                className="w-[37rem] object-cover rounded-lg"
                alt="Service Image"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Get the HomeOwner mobile app today */}

      <div
        className="h-[40rem] mb-14 relative"
        style={{
          backgroundColor: "rgba(255, 178, 0, 0.12)",
        }}
      >
        <h1 className="text-center text-[#101828] text-[44px] md:text-[36px]">
          Get the HomeOwner mobile app today
        </h1>

        <a
          href={downloadUrl}
          className="bg-[#FF9D00] h-[65px] w-[184px] flex justify-center items-center mx-auto rounded-[12px]"
        >
          <p className="text-white text-[36px] md:text-[28px]">It's Free!</p>
        </a>

        <p className="text-center text-[20px] md:text-[18px] text-[#667085]">
          Download and get access to all the features
        </p>

        <img
          src={require("../iamges/Group_1000004948-removebg-preview.png")}
          alt=""
          style={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </div>
      <Marquee
        speed={120}
        style={{
          marginBottom: 20,
          fontFamily: "Plus Jakarta Sans",
          fontSize: "110px",
          fontStyle: "normal",
          fontWeight: "700",
        }}
      >
        <h1 className="lg:text-[110px] text-[48px]">HomeOwnermadeiteasy!</h1>
      </Marquee>
    </>
  );
};

export default WhatWeProvide;

//  <div className="flex flex-col">
//             {cards.map((card, index) => (
//               <div
//                 key={index}
//                 onMouseEnter={() => setHovered(index)}
//                 onMouseLeave={() => setHovered(null)}
//                 className="lg:w-[20rem] lg:h-[25rem] w-[15rem] h-[20rem] rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105"
//               >
//                 {hovered === index ? (
//                   <div className="h-5 rounded-[20px] border border-[rgba(0,0,0,0.08)] bg-[rgba(255,157,0,0.06)] flex flex-col justify-evenly p-4 transition-all duration-300 ease-in-out">
//                     <img
//                       src={card.imgSrc}
//                       className="w-[4rem] mx-auto"
//                       alt={card.label}
//                     />
//                     <h1 className="text-xl font-bold text-center">
//                       {card.label}
//                     </h1>
//                     <p className="text-center">{card.description}</p>
//                     <div className="text-center">
//                       <button className="mt-4 py-2 px-4 bg-orange-400 text-white rounded-lg">
//                         Download
//                       </button>
//                     </div>
//                   </div>
//                 ) : (
//                   <h1 className="text-2xl font-bold text-center p-4 cursor-pointer">
//                     {card.label}
//                   </h1>
//                 )}
//               </div>
//             ))}
//           </div>
