const getPlatform = (): string => {
  const userAgent: string =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Check for Android
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // Check for iOS (iPhone, iPad, iPod)
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  // Check for macOS
  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.platform)) {
    return "Mac";
  }

  // Check for Windows
  if (/Win32|Win64|Windows|WinCE/.test(navigator.platform)) {
    return "Windows";
  }

  // Check for Linux
  if (/Linux/.test(navigator.platform)) {
    return "Linux";
  }

  return "Unknown";
};

export default getPlatform;
