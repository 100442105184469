import { CSSProperties } from "react";
import NavBar from "./NavBar";
import HeroSection from "./HeroSection";
import InfoSection from "./InfoSection";
import BottomSection from "./BottomSection";
import Cards from "./Cards";
import AndMore from "./AndMore";
import WhatWeProvide from "./WhatWeProvide";

export type Styles = {
  [key: string]: CSSProperties;
};

function App() {
  return (
    <div className="static">
      <NavBar />
      <HeroSection />
      <InfoSection />
      <Cards />
      <AndMore />
      <WhatWeProvide />
      <BottomSection />
      {/* <BottomSection /> */}
    </div>
  );
}

export default App;
