import "../styles/InfoSection.css";

import React, { useEffect, useState } from "react";

const InfoSection = () => {
  const [isBelow981, setIsBelow981] = useState(window.innerWidth < 981);

  useEffect(() => {
    const handleResize = () => {
      setIsBelow981(window.innerWidth < 981);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="grid place-items-center lg:mt-0 mt-72 text-center px-4">
        <div
          style={{ background: "rgba(255, 178, 0, 0.12)" }}
          className="mt-6 "
        >
          <h1 className="text-base font-bold text-[#FF9D00] bg-[rgba(255, 178, 0, 0.12)]">
            Features
          </h1>
        </div>
        <h1
          className="text-4xl font-semibold mt-2 text-[#101828]"
          style={{ fontWeight: "500" }}
        >
          Explore Our{" "}
          <span style={{ fontWeight: "800" }}>Comprehensive Features</span>
        </h1>
        <p className="mt-4 text-xl">
          Open a feature-rich homeowner’s account in
          <span className="block lg:inline text-xl">
            minutes and enjoy exclusive benefits.
          </span>
        </p>
      </div>
    </>
  );
};

export default InfoSection;
