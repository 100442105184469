import React from "react";

const AndMore = () => {
  return (
    <img
      className="w-[57rem] mx-auto mt-4"
      src={require("../iamges/last card.png")}
      alt=""
    />
  );
};

export default AndMore;
