import React from "react";
import "../styles/navbar.css";
import { Link } from "react-router-dom";
import getPlatform from "../utils/getPlatform";

const NavBar = () => {
  const platform = getPlatform();
  console.log("User Platform:", platform);
  const downloadLinks: { [key: string]: string } = {
    Android:
      "https://play.google.com/store/apps/details?id=com.arptix.homeowner",
    iOS: "https://apps.apple.com/ca/app/homeowner/id6448795356",
    Windows:
      "https://play.google.com/store/apps/details?id=com.arptix.homeowner",
    Mac: "https://apps.apple.com/ca/app/homeowner/id6448795356",
    Linux: "https://www.example.com/linux-download",
  };

  // Fallback URL if the platform is unknown
  const downloadUrl = downloadLinks[platform] || "https://www.example.com";
  return (
    <div className="flex justify-between items-center bg-white border border-gray-200 rounded-full shadow-lg p-4 mb-4 lg:w-3/5 md:w-3/5 mx-auto h-16">
      <Link to={"/"}>
        <div className="flex items-center">
          <img className="w-8 h-8 text-green-600 mr-2" src="./favicon.png" />
        </div>
      </Link>
      <a
        href={downloadUrl}
        className="bg-orange-400 text-white font-semibold rounded-full px-6 py-2 transition-colors duration-300 hover:bg-orange-500"
      >
        Download for free
      </a>
    </div>
  );
};

export default NavBar;
